module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Profil Dance","short_name":"Profil","start_url":"/","background_color":"#d8d5dc","theme_color":"#d4a88c","display":"standalone","icon":"icons/icon.png","icons":[{"src":"icons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"icons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AIzaSyCAPtVSPU0-3XS1VZtQDfq28bTHPUGh55I","G-D4XZGJB8FN"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
